import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../../components/seo'
import NewsSection from '../../components/NewsSection'
import * as s from '../../pages/news.module.scss'
import Link from '../../components/Common/Link'
import cn from 'classnames'

const BimForum: React.FC = () => {
  const title = 'SIGNAL принял участие в BIM-форуме 2023 в Москве'
  return (
    <>
      <SEO
        title="SIGNAL принял участие в BIM-форуме 2023"
        description="SIGNAL презентовал на BIM-форуме 2023 свои продукты DASHBOARD, INSPECTION, DOCS и TOOLS для управления строительством с BIM и показал каким должен быть стандарт цифрового строительства."
      />
      <NewsSection
        title={title}
        dateData={{
          date: '09.06.2023',
        }}
        rayContent
      >
        <p className={s.text}>
          7 июня в Москве прошел BIM-форум 2023{' '}
          <Link
            href="https://bimforum.pro/"
            rel="nofollow noreferrer"
            target="_blank"
          >
            bimforum.pro
          </Link>
        </p>
        <div className="flex justify-center">
          <iframe
            width="750"
            height="450"
            src="https://pa.sgnl.pro/public/photo/rmv2r?yaw=-74.6&fov=80&pitch=27.2&azimut=0"
            title={title}
            allow="xr-spatial-tracking; gyroscope; accelerometer"
            allowFullScreen
          ></iframe>
        </div>
        <p className={s.text}>
          SIGNAL представил свои решения на стенде и презентовал новый модуль
          SIGNAL DOCS.
        </p>
        <div className="flex justify-center">
          <iframe
            width="750"
            height="450"
            src="https://pa.sgnl.pro/public/photo/r8y4b?yaw=-42.7&fov=80&pitch=20.5&azimut=0"
            title={title}
            allow="xr-spatial-tracking; gyroscope; accelerometer"
            allowFullScreen
          ></iframe>
        </div>
        <p className={s.text}>
          Александр Попов, Руководитель разработки продукта выступил с докладом
          и презентовал новый модуль DOCS, а также рассказал о новинках в
          продукте и планах по дальнейшей разработке.
        </p>
        <div className="flex">
          <StaticImage
            className={s.imageBlock}
            src="../../assets/images/news/2023-06-09-bim-forum/image-1.png"
            alt={title}
            title={title}
            placeholder="blurred"
          />
          <StaticImage
            className={cn('mx-2', s.imageBlock)}
            src="../../assets/images/news/2023-06-09-bim-forum/image-2.png"
            alt={title}
            title={title}
            placeholder="blurred"
          />
          <StaticImage
            className={s.imageBlock}
            src="../../assets/images/news/2023-06-09-bim-forum/image-3.png"
            alt={title}
            title={title}
            placeholder="blurred"
          />
        </div>
      </NewsSection>
    </>
  )
}

export default BimForum
